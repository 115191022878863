export class SendEmail {
    FirstName: string;
    LastName: string;
    CompanyName: string;
    Address: string;
    City: string;
    ZipCode: string;
    State: string;
    Country: string;
    EmailID: string;
    MobileNo: string;
    Requirement: string;
  }
  