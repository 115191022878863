import { DeliveryImage } from './../models/DeliveryImage';
import { Injectable } from '@angular/core';
import { Global } from "src/app/common/global";
import { Http, URLSearchParams, RequestOptions, Response, Headers } from "@angular/http";
import { Observable } from "rxjs";
import { Tracking } from "src/app/models/tracking";
import "rxjs/add/operator/map";
import { MultipleTracking } from "src/app/models/multipletracking";
import { SendEmail } from "src/app/models/sendemail";
import { error } from "util";
import { Delivery } from 'src/app/models/Delivery';
import { HttpClient, HttpEvent, HttpParams, HttpRequest, HttpHeaders } from '@angular/common/http';
import { concat } from 'rxjs/internal/observable/concat';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private global: Global = new Global();

  constructor(private http: Http) { }

  GetTracking(TrackingNo): Observable<Tracking[]> {
    return this.http.get(this.global.baseUrl + "Tracking/" + TrackingNo).map(
      (response: Response) => {
        var result = response.json();
        return result;
      },
      error => this.handleError(error)
    );
  }

  GetDeliveryImage(ImageID): Observable<DeliveryImage[]> {
    return this.http.get(this.global.baseUrl + "DeliveryImage/" + ImageID).map(
      (response: Response) => {
        var result = response.json();
        return result;
      },
      error => this.handleError(error)
    );
  }

  GetDeliveryStatus(TrackingId): Observable<Delivery[]> {
    return this.http
      .get(this.global.baseUrl + "Delivery/" + TrackingId)
      .map(
      (response: Response) => {
        var result = <Delivery[]>response.json();
        return result;
      },
      error => this.handleError(error)
      );
  }

  GetCountDelivery(TrackingId): Observable<Delivery[]> {
    return this.http
      .get(this.global.baseUrl + "GetCountDelivery/" + TrackingId)
      .map(
      (response: Response) => {
        var result = <Delivery[]>response.json();
        return result;
      },
      error => this.handleError(error)
      );
  }

  GetTravelInfo(TrackingId): any {
    return this.http.get(this.global.baseUrl + "TravelInfo/" + TrackingId).map(
      (response: Response) => {
        var result = response.json();
        return result;
      },
      error => this.handleError(error)
    );
  }

  GetTDeliverysStatus(TrackingId): any {
    return this.http
      .get(this.global.baseUrl + "GetDelivery/" + TrackingId)
      .map(
      (response: Response) => {
        var result = <Delivery[]>response.json();
        return result;
      },
      error => this.handleError(error)
      );
  }

  GetMultiTracking(tracking: MultipleTracking, TrackingNos): any {
    let params: URLSearchParams = new URLSearchParams();
    params.set("MultiTrackingNo", TrackingNos);

    let requestOptions = new RequestOptions();
    requestOptions.search = params;

    return this.http.post(this.global.baseUrl + "MultipleTracking", params).map(
      (response: Response) => {
        var result = response.json();
        return result;

      },
      error => this.handleError(error)
    );
  }

  SendEmail(sendemail: SendEmail) {
    let params: URLSearchParams = new URLSearchParams();
    params.set("FirstName", sendemail.FirstName);
    params.set("LastName", sendemail.LastName);
    params.set("CompanyName", sendemail.CompanyName);
    params.set("Address", sendemail.Address);
    params.set("City", sendemail.City);
    params.set("ZipCode", sendemail.ZipCode);
    params.set("State", sendemail.State);
    params.set("Country", sendemail.Country);
    params.set("EmailID", sendemail.EmailID);
    params.set("MobileNo", sendemail.MobileNo);
    params.set("Requirement", sendemail.Requirement);

    let requestOptions = new RequestOptions();
    requestOptions.search = params;

    return this.http.post(this.global.baseUrl + "SendEmail", params).map(
      a => {
        console.log("Response:" + Response);
        console.log("Status:" + a.status);
        console.log("StatusText:" + a.statusText);
        console.log("Error:" + error);
        return a.status == 200;
        //return Response;
      },
      err => this.handleError(err)
    );
  }

  private handleError(error: Response | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || "";
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }
}
