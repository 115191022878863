import { Delivery } from './../../models/Delivery';
import { Component, OnInit } from '@angular/core';
import { TrackingService } from "src/app/services/tracking.service";
import { Tracking } from "src/app/models/tracking";
import { DeliveryImage } from "src/app/models/DeliveryImage";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ElementRef } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Directive, Input, } from '@angular/core';
import { catchError } from 'rxjs/internal/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-trackingdetail',
  templateUrl: './trackingdetail.component.html',
  styleUrls: ['./trackingdetail.component.css']
})
export class TrackingdetailComponent implements OnInit {
  tracking = {};


  travelInfo: any;
  maxOrderDate: any;
  PODUrl = '';


  DeliveryImage = {};
  Delivery = {};
  CountDelivery = {};
  TDelivery = {};
  ID;
  imagePath = {};
  tgridData: any[];
  srcUrl: string = 'https://shipway.njbworldwide.com/shipway.html';

  //@ViewChild('search') searchElement: ElementRef;y
  @ViewChild('myDiv') myDivRef: ElementRef;

  constructor(
    private trackingService: TrackingService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService
    // private _sanitizer: DomSanitizer
  ) {
  }


  prepareForDisplay(tempDate) {

    this.travelInfo = [];

    tempDate.forEach(item => {
      // const date = this.formattedDate(item.EntryDate);
      // const time = this.formattedTime(item.TravelDateTime);
      const date = item.EntryDate;
      const time = item.TravelDateTime;

      item.key = date;
      item.timeToDisplay = time;

      const index = this.travelInfo.findIndex(x => x.key === date);

      if (index < 0) {
        this.travelInfo.push({
          key: date,
          itemList: []
        });
      }
    });

    this.travelInfo.forEach(item => {
      item.itemList = tempDate.filter(d => d.key === item.key);
    });
  }

  formattedDate(dateString) {

    const date = new Date(dateString);

    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    return day + '/' + (month > 9 ? month : '0' + month) + '/' + year;
  }

  formattedTime(dateString) {

    const day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const date = new Date(dateString);

    const dayName = day[date.getUTCDay()];
    const h = date.getUTCHours();
    const m = date.getUTCMinutes();

    return dayName + ', ' + (h > 9 ? h : '0' + h) + ':' + (m > 9 ? m : '0' + h) + ' ' + (h >= 12 ? 'PM' : 'AM');
  }

  setMaxOrderDate(list) {
    const date = new Date(Math.min.apply(null, list.map((e) => {
      return new Date(e.EntryDate);
    })));

    // this.maxOrderDate = this.formattedDate(date.toString());
    this.maxOrderDate = date;
  }

  sortByDateAndSetPODUrl(list) {
    list.sort((a, b) => {
      return (new Date(b.EntryDate).getTime() - new Date(a.EntryDate).getTime());
    });

    this.PODUrl = list[0].ImageSignature;
    console.log('this.PODUrl ' + this.PODUrl);
  }

  ngOnInit() {

    this.myDivRef.nativeElement.scrollIntoView();

    this.spinner.show();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.params.subscribe(params => {
      this.ID = params["id"];
      this.srcUrl = 'https://shipway.njbworldwide.com/shipway.html?CnNumber=' + this.ID;

      //  this.srcUrl = 'https://shipway.njbworldwide.com/Shipway.php?CnNumber=' + this.ID;


      // this.srcUrl = 'https://localhost:803/Shipway.html?CnNumber=' + this.ID;

      // setTimeout(function () {
      //   console.log('hide');
      //   $('#scan_order').hide();

      //   // var f = $('#frameNJB');
      //   // f.load(function () {
      //   //   f.contents().find('scan_order').hide();
      //   // })
      // }, 5000);


      // setInterval(() => {
      //   console.log('hide');
      //   $('#scan_order').hide();
      //   console.log($("#frameNJB").contents());
      //   console.log($('#scan_order'));
      // }, 5000);


    });

    this.trackingService.GetTracking(this.ID).subscribe(response => {

      if (response != null) {
        (this.tracking = response),
          this.trackingService
            .GetDeliveryStatus(response["TrackingID"])
            .subscribe(response => {
              this.Delivery = response;

              if (response == null) {
                this.Delivery = {
                  DeliveryID: '',
                  TrackingID: '',
                  DRSNo: '',
                  DeliveryDate: '',
                  DeliveryDateSr: 0,
                  DeliveryArea: '',
                  ReceiverName: '',
                  DeliveryStatus: '',
                  EmployeeName: ''
                };
              }
            });

        this.trackingService
          .GetTravelInfo(response["TrackingNo"])
          .subscribe(travelResponse => {
            if (travelResponse && travelResponse.length > 0) {
              this.travelInfo = travelResponse;
              // this.sortByDateAndSetPODUrl(this.travelInfo);
              this.setMaxOrderDate(this.travelInfo);
              this.prepareForDisplay(this.travelInfo);
            }
          });

        this.trackingService
          .GetTDeliverysStatus(response["TrackingID"])
          .subscribe(response => {

            console.log('GetTDeliverysStatus ' + response);
            this.TDelivery = response;

          });

        this.trackingService
          .GetCountDelivery(response["TrackingID"])
          .subscribe(response => {
            console.log('GetCountDelivery ' + response);
            this.CountDelivery = response;

          });

        this.trackingService
          .GetDeliveryImage(response["ImageId"])
          .subscribe(response => {
            if (response != null)
              console.log('GetDeliveryImage ' + response);
            this.DeliveryImage = response;
          });




        this.spinner.hide();
      } else {
        this.spinner.hide();
        alert("Invalid Tracking Number");
        this.router.navigate(['/']);
      }
    });
  }

}
