import { News } from 'src/app/models/News';
import { Injectable } from '@angular/core';
import { Global } from "src/app/common/global";
import { Http, URLSearchParams, RequestOptions, Response } from "@angular/http";
import { Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private global: Global = new Global();

  constructor(private http: Http) { }

  GetNews(): Observable<News[]> {
    return this.http.get(this.global.baseUrl + "ActiveNews/").map(
      (response: Response) => {
        var result = response.json();
        return result;
      },
      error => this.handleError(error)
    );
  }

  GetNewsActive(): Observable<News[]> {
    return this.http.get(this.global.baseUrl + "News/" + true).map(
      (response: Response) => {
        var result = response.json();
        return result;
      },
      error => this.handleError(error)
    );
  }

  private handleError(error: Response | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || "";
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }
}
