import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrackingService } from 'src/app/services/tracking.service';
import { SendEmail } from 'src/app/models/sendemail';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.css']
})
export class InquiryComponent implements OnInit {
  public sendemail = new SendEmail();
  constructor(
    private trackingService: TrackingService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  SendEmail(sendemail) {
    this.spinner.show();
    this.trackingService.SendEmail(this.sendemail).subscribe(res => {
      if (res == true) {
        this.spinner.hide();
        alert('Request Submitted successfully');
        this.sendemail.FirstName = '';
        this.sendemail.LastName = '';
        this.sendemail.CompanyName = '';
        this.sendemail.Address = '';
        this.sendemail.City = '';
        this.sendemail.ZipCode = '';
        this.sendemail.State = '';
        this.sendemail.Country = '';
        this.sendemail.EmailID = '';
        this.sendemail.MobileNo = '';
        this.sendemail.Requirement = '';
      }
    });
  }

}
