import { NewsService } from './services/news.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";

import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AboutComponent } from './layout/about/about.component';
import { ContactComponent } from './layout/contact/contact.component';
import { HomeComponent } from './layout/home/home.component';
import { InquiryComponent } from './layout/inquiry/inquiry.component';
import { MultipletrackingComponent } from './layout/multipletracking/multipletracking.component';
import { ServiceComponent } from './layout/service/service.component';
import { TrackingdetailComponent } from './layout/trackingdetail/trackingdetail.component';
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { TrackingService } from "src/app/services/tracking.service";
import { ShipWayComponent } from 'src/app/layout/shipWay/shipWay.component';
import { SafePipe } from 'src/app/safe-pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    HomeComponent,
    InquiryComponent,
    MultipletrackingComponent,
    ServiceComponent,
    TrackingdetailComponent,
    ShipWayComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    NgxSpinnerModule,
    RouterModule.forRoot(
      [
        { path: '', component: HomeComponent },
        { path: 'about', component: AboutComponent },
        { path: 'service', component: ServiceComponent },
        { path: 'inquiry', component: InquiryComponent },
        { path: 'contact', component: ContactComponent },
        { path: 'multipletracking', component: MultipletrackingComponent },
        { path: 'trackingdetail/:id', component: TrackingdetailComponent },
        { path: 'shipWay/:id', component: ShipWayComponent }
      ],
      { useHash: true }
    )
  ],
  providers: [
    TrackingService,
    NewsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
