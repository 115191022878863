import { News } from 'src/app/models/News';
import { Component, OnInit } from '@angular/core';
import { NewsService } from 'src/app/services/news.service';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  News = {};
  ActiveNews = {};

  constructor(
    private newsService: NewsService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {

    this.newsService.GetNews().subscribe(response => {
      if (response != null) {
        (this.News = response),
          this.newsService
            .GetNewsActive()
            .subscribe(response => {
              this.ActiveNews = response;
            });
      }
    });
  }
}


