import { Delivery } from './../../models/Delivery';
import { Component, OnInit } from '@angular/core';
import { TrackingService } from "src/app/services/tracking.service";
import { Tracking } from "src/app/models/tracking";
import { DeliveryImage } from "src/app/models/DeliveryImage";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from '@angular/platform-browser';
//import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-shipWay',
  templateUrl: './shipWay.component.html'
})
export class ShipWayComponent implements OnInit {
  ID;
  url: string;


  constructor(
    private trackingService: TrackingService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }
  //   $(document).ready(function () {

  //     debugger;
  //     var iframe = $('iframe');
  //     iframe.contents().find('p').hide();




  //     $(function () {
  //       debugger;
  //       // var f = $('#ship_oscan_iframe');
  //       var f = $('iframe');
  //       f.load(function () {
  //         debugger;
  //         f.contents().find('p').hide();

  //       })
  //     })



  //     this.ID = "test";
  //     $('#scan_order').click(function () {
  //       debugger;
  //       alert('test');
  //     });

  //     $('.panel-heading').val(this.ID);
  //     $("p").hide();


  //     alert("The paragraph was clicked.");
  //     setTimeout(() => {
  //       this.ID = "test";
  //       $('#scan_order_id').val(this.ID);
  //       var sup = $('#scan_order_id').val(this.ID);
  //       console.log('test ' + sup);  // sup is a variable here
  //       $('#shipway_order_id').val(this.ID);
  //       var sup1 = $('#shipway_order_id').val(this.ID);
  //       console.log('test1 ' + sup1);  // sup is a variable here
  //       $("p").hide();

  //       $('#scan_order_id').text(this.ID);
  //       var sup2 = $('#scan_order_id').text(this.ID);
  //       console.log('test2 ' + sup2);  // sup is a variable here
  //       $('#shipway_order_id').text(this.ID);
  //       var sup12 = $('#shipway_order_id').text(this.ID);
  //       console.log('test12 ' + sup12);  // sup is a variable her
  //       $("p").hide();
  //       $("p").css('visibility', 'hidden');


  //       debugger;
  //       var f = $('#ship_oscan_iframe');
  //       f.load(function () {
  //         debugger;
  //         f.contents().find('p').hide();
  //       })

  //     }, 10000);

  //   });


  //   this.spinner.show();


  //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;

  //   this.route.params.subscribe(params => {

  //     this.ID = params["id"];
  //     // debugger;
  //     // $('#scan_order_id').val(this.ID);
  //     // var sup = $('#scan_order_id').val(this.ID);
  //     // console.log('test ' + sup);  // sup is a variable here
  //     // $('#shipway_order_id').val(this.ID);
  //     // var sup1 = $('#shipway_order_id').val(this.ID);
  //     // console.log('test1 ' + sup1);  // sup is a variable here

  //   });


  // }

  // public btnCancel_Click() {
  //   console.log("t");
  // }
}
